import type { RouteLocationNormalizedLoaded } from '#vue-router';
import DOMPurify from 'isomorphic-dompurify';

export async function usePublicArticleNewsHelpers(
  route: RouteLocationNormalizedLoaded,
) {
  const { getNewsCategoryNameById, isPrArticle } = await useCategories();

  const keyword = computed(() => {
    return route.query.s;
  });

  const order = computed(() => {
    return route.query.order;
  });

  const categoryId = computed(() => {
    return route.query.ct;
  });

  const categoryName = computed(() => {
    return categoryId.value
      ? getNewsCategoryNameById(Number(categoryId.value))
      : '';
  });

  const tag = computed(() => {
    return DOMPurify.sanitize((route.query.tag as string) ?? '');
  });

  const heading = computed(() => {
    if (order.value === 'rank') {
      return '週間ランキング';
    }

    if (keyword.value) {
      return `「${keyword.value}」の検索結果`;
    }

    if (categoryId.value) {
      return `${categoryName.value}の記事一覧`;
    }

    if (tag.value) {
      return `タグ「${tag.value}」の記事一覧`;
    }

    return '新着記事一覧';
  });

  const { setBreadcrumbs } = useBreadcrumbs();

  function configureBreadcrumbs() {
    const breadcrumbs: BreadCrumb[] = [
      {
        to: route.path,
        text: 'ニュース',
      },
    ];

    if (heading.value !== '新着記事一覧') {
      breadcrumbs.push({
        text: heading.value,
      });
    }

    setBreadcrumbs(breadcrumbs);
  }

  return {
    tag,
    keyword,
    heading,
    categoryId,
    order,
    categoryName,
    isPrArticle,
    configureBreadcrumbs,
  };
}
